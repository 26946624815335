import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  isLoggedIn: false,
  checkedAuth: false,
  hasSetup: false,
  // userInfo: {
  //   first_name: '',
  //   last_name: '',
  //   email: '',
  //   date_joined: ''
  // }
  userInfo: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, action) {
      state.user = { ...state.user, ...action.payload }
    },
    setCheckedAuth(state, action) {
      state.checkedAuth = action.payload
    },
    setLoggedIn(state, action) {
      state.isLoggedIn = action.payload
    },
    setHasSetup(state, action) {
      state.hasSetup = action.payload
    },
    setUserInfo(state, action) {
      state.userInfo = action.payload
    },
    resetUserState(state, action) {
      state.isLoggedIn = false
      state.checkedAuth = true
      state.userInfo = null
    },
  },
})

export const { setUser, setCheckedAuth, setLoggedIn, resetUserState, setHasSetup, setUserInfo } =
  userSlice.actions

export default userSlice.reducer
