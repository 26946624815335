import { useDispatch } from 'react-redux'
import { setLoggedIn, setCheckedAuth } from 'redux/ducks/user'
import { verifyUserToken, refreshUserToken } from 'api/services/user'

const getStoredToken = (key) => {
  const token = localStorage.getItem(key) || sessionStorage.getItem(key)
  return token ? token : null
}

const removeStoredToken = (key) => {
  localStorage.removeItem(key)
  sessionStorage.removeItem(key)
}

const setStoredToken = (key, val) => {
  localStorage.setItem(key, val)
  sessionStorage.setItem(key, val)
}

export const useRefreshUser = () => {
  const dispatch = useDispatch()

  const accessToken = getStoredToken('jwt_access_token')
  const refreshToken = getStoredToken('jwt_refresh_token')

  const verifyRefreshToken = async () => {
    if (accessToken) {
      try {
        await verifyUserToken({ token: accessToken })
        dispatch(setLoggedIn(true))
      } catch (err) {
        if (+err?.response?.status === 401) {
          removeStoredToken('jwt_access_token')
          if (refreshToken) {
            try {
              const refresh = await refreshUserToken({ refresh: refreshToken })
              setStoredToken('jwt_access_token', refresh.data.access)
              dispatch(setLoggedIn(true))
            } catch (err) {
              removeStoredToken('jwt_access_token')
              removeStoredToken('jwt_refresh_token')
              dispatch(setLoggedIn(false))
            }
          }
        } else {
          removeStoredToken('jwt_access_token')
          dispatch(setLoggedIn(false))
        }
      }
    }
    dispatch(setCheckedAuth(true))
  }

  return { verifyRefreshToken }
}
