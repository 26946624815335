import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  frames: null,
}

const framesSlice = createSlice({
  name: 'frames',
  initialState,
  reducers: {
    setFramesSlice(state, action) {
      state.frames = action.payload
    },

    addFrameSlice(state, action) {
      const frame = action.payload
      if (state.frames) {
        state.frames = state.frames.concat(frame)
      }
    },

    removeFrameSlice(state, action) {
      const frameArray = action.payload
      if (state.frames) {
        if (frameArray.id) {
          state.frames = state.frames.filter((fm) => fm.id !== frameArray.id)
        } else {
          const ids = frameArray.ids
          state.frames = state.frames.filter((fm) => !ids.includes(fm.id))
        }
      }
    },

    changeFrameSlice(state, action) {
      const frame = action.payload
      if (state.frames) {
        state.frames = state.frames.map((fm) => {
          if (fm.id === frame.id) {
            return { ...fm, ...frame }
          }
          return fm
        })
      }
    },
  },
})

export const { setFramesSlice, addFrameSlice, removeFrameSlice, changeFrameSlice } = framesSlice.actions

export default framesSlice.reducer
