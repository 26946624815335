import React from 'react'
import './Logo.scss'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

const Logo = ({ variant }) => {
  const theme = useSelector((state) => state.theme.theme)

  return (
    <NavLink className={`logo-btn ${variant || 'long'}`} to="/">
      {theme === '1' || theme === 1 ? (
        variant === 'short' ? (
          <img className="short-logo" src="/icons/logo/logo-light-short.png" alt="maoni-logo" />
        ) : (
          <img className="long-logo" src="/icons/logo/logo-light.png" alt="maoni-logo" />
        )
      ) : variant === 'short' ? (
        <img className="short-logo" src="/icons/logo/logo-dark-short.png" alt="maoni-logo" />
      ) : (
        <img className="long-logo" src="/icons/logo/logo-dark.png" alt="maoni-logo" />
      )}
    </NavLink>
  )
}

export default Logo
