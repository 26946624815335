import React, { useEffect, useState } from 'react'
import NavLinkBtn from '../../buttons/NavLinkBtn'
import './Sidebar.scss'
import HomeIcon from '@mui/icons-material/HomeOutlined'
import DashboardIcon from '@mui/icons-material/DashboardCustomizeOutlined'
import ChartIcon from '@mui/icons-material/StackedBarChartOutlined'
import BusinessIcon from '@mui/icons-material/Business'
import MapIcon from '@mui/icons-material/Map'
import IconButton from '../../buttons/IconButton'
import classnames from 'classnames'
import { useLocation } from 'react-router-dom'
import AccountIcon from '@mui/icons-material/AccountCircleOutlined'
import NotificationsIcon from '@mui/icons-material/NotificationsNone'
import LogoutIcon from '@mui/icons-material/Logout'
import ForumIcon from '@mui/icons-material/ForumOutlined'
import Logo from '../../logo/Logo'
import { useTranslation } from 'react-i18next'
import SidebarFooter from './SidebarFooter'
import HelpIcon from '@mui/icons-material/HelpOutline'
import InputIcon from '@mui/icons-material/MenuOpen'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { useUserAuth } from 'custom-hooks/api/useUserAuth'
import { useMediaQuery } from 'react-responsive'
import { isMobile, isTablet } from 'react-device-detect'
import InstallMobileIcon from '@mui/icons-material/InstallMobile'
import { useSelector } from 'react-redux'

const Sidebar = () => {
  const isMobileDevice = useMediaQuery({ query: '(max-width: 1024px)' })
  const isPWAInstalled = useSelector((state) => state.pwa.isPWAInstalled)
  const location = useLocation().pathname
  const { t } = useTranslation()
  const noRoutes = ['qr-feedback', 'storybook', 'login', 'register', 'account-setup']
  const [isExtended, setIsExtended] = useState(isMobileDevice ? false : true)
  const { userLogout } = useUserAuth()

  const handleLogout = () => {
    handleMenuClick()
    userLogout()
  }

  const handleMenuClick = () => {
    // only if small width
    isExtended && isMobileDevice && setIsExtended(false)
  }

  if (noRoutes.find((loc) => location.includes(loc))) return null
  return (
    <div className={classnames('sidebar-container', { extended: isExtended })}>
      <div className="sidebar-logo-expand">
        <Logo variant={isExtended ? 'long' : 'short'} />
        <div className="sidebar-expand-btn">
          <IconButton
            // variant='filled'
            title={t('sidebar-expand-btn')}
            onClick={() => setIsExtended(!isExtended)}
          >
            {isExtended ? <InputIcon /> : <InputIcon />}
          </IconButton>
        </div>
      </div>
      <div className="sidebar-btns">
        <NavLinkBtn onClick={handleMenuClick} to="/">
          <HomeIcon />
          {isExtended && t('navbar-home-btn')}
        </NavLinkBtn>
        <NavLinkBtn onClick={handleMenuClick} to="/companies/">
          <BusinessIcon />
          {isExtended && t('navbar-companies-btn')}
        </NavLinkBtn>
        <NavLinkBtn onClick={handleMenuClick} to="/locations/">
          <MapIcon />
          {isExtended && t('navbar-locations-btn')}
        </NavLinkBtn>
        <NavLinkBtn onClick={handleMenuClick} to="/creator">
          <DashboardIcon />
          {isExtended && t('navbar-creator-btn')}
        </NavLinkBtn>
        <NavLinkBtn onClick={handleMenuClick} to="/feedback/">
          <ForumIcon />
          {isExtended && t('navbar-feedback-btn')}
        </NavLinkBtn>
        <NavLinkBtn onClick={handleMenuClick} to="/stats/">
          <ChartIcon />
          {isExtended && t('navbar-stats-btn')}
        </NavLinkBtn>
        <NavLinkBtn onClick={handleMenuClick} to="/account/">
          <AccountIcon />
          {isExtended && t('navbar-account-btn')}
        </NavLinkBtn>
        <NavLinkBtn onClick={handleMenuClick} to="/faq/">
          <HelpIcon />
          {isExtended && t('navbar-faq-btn')}
        </NavLinkBtn>
        <NavLinkBtn onClick={handleMenuClick} to="/notifications/" disabled>
          <NotificationsIcon />
          <div>{isExtended && t('navbar-notifications-btn')}</div>
          {isExtended && (
            <IconButton title={t('sidebar-notifications-disabled')}>
              <InfoIcon />
            </IconButton>
          )}
        </NavLinkBtn>

        {(isMobile || isTablet) && !isPWAInstalled && (
          <NavLinkBtn onClick={handleMenuClick} to="/app/">
            <InstallMobileIcon />
            {isExtended && t('install-app-prompt')}
          </NavLinkBtn>
        )}
        <NavLinkBtn to="/logout/" onClick={handleLogout}>
          <LogoutIcon />
          {isExtended && t('navbar-logout-btn')}
        </NavLinkBtn>
      </div>
      <SidebarFooter compact={!isExtended} />
    </div>
  )
}

export default Sidebar
