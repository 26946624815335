import i18n from 'i18next'
import { Translation, initReactI18next } from 'react-i18next'
import enUS from './translationsEn.json'
import plPL from './translationsPl.json'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: enUS,
      },
      pl: {
        translation: plPL,
      },
    },
    lng: window.localStorage.getItem('language') || 'en-US',
    fallbackLng: ['en-US'],

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },

    returnEmptyString: false,
    saveMissing: true,
    missingKeyHandler: (lng, ns, key, fallbackValue, updateMissing, options) => {
      if (enUS && enUS[key] === undefined) {
        setTimeout(console.log.bind(console, `"${key}": "",`))
      }
    },
    parseMissingKeyHandler: (key) => '#' + key,
  })

export function getTranslation(key, interpolations) {
  return <Translation>{(t) => t(key, interpolations)}</Translation>
}
export default i18n
