import React from 'react'
import { NavTabs, Tab } from 'components/tabs/NavTabs'
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined'
import ViewDayOutlinedIcon from '@mui/icons-material/ViewDayOutlined'
import QrCodeOutlinedIcon from '@mui/icons-material/QrCodeOutlined'
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid'
import { useTranslation } from 'react-i18next'
import './CreatorSidebar.scss'
import Typography from 'components/typography/Typography'
import { useLocation } from 'react-router-dom'

const CreatorSidebar = () => {
  const { t } = useTranslation()
  const location = useLocation().pathname

  const titleMaps = {
    general: t('creator-general-info-title'),
    forms: t('creator-forms-title'),
    frames: t('creator-frames-title'),
    codes: t('creator-codes-title'),
  }
  const currentKey = Object.keys(titleMaps).find((key) => location.includes(key))
  const currentTitle = currentKey ? titleMaps[currentKey] : t('creator-general-info-title')

  if (!location.includes('creator') || location.includes('editor')) return null

  return (
    <div className="creator-sidebar-container">
      <Typography variant="h1">{currentTitle}</Typography>
      <NavTabs direction="horizontal">
        <Tab end to="/creator">
          <HelpCenterOutlinedIcon />
          {t('creator-sidebar-general')}
        </Tab>
        <Tab to="/creator/forms">
          <ViewDayOutlinedIcon />
          {t('creator-sidebar-forms')}
        </Tab>
        <Tab to="/creator/frames">
          <QrCodeOutlinedIcon />
          {t('creator-sidebar-frames')}
        </Tab>
        <Tab to="/creator/codes">
          <PhoneAndroidIcon />
          {t('creator-sidebar-codes')}
        </Tab>
      </NavTabs>
    </div>
  )
}

export default CreatorSidebar
