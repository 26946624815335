import React from 'react'
import './Typography.scss'
import classnames from 'classnames'

const Typography = ({ variant, children, className, ...props }) => {
  return (
    <div
      className={classnames(
        {
          [`typography typography-${variant}`]: variant,
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  )
}

export default Typography
