import React from 'react'
import './IconButton.scss'
import classnames from 'classnames'

const IconButton = ({ className, children, selected, variant, borderType, ...props }) => {
  const variantClassname = {
    outlined: 'outlined',
    filled: 'filled',
    noBorder: 'no-border',
  }

  return (
    <button
      className={classnames('icon-btn btn', className, variantClassname[variant], borderType)}
      type="button"
      {...props}
    >
      {children}
    </button>
  )
}

export default IconButton
