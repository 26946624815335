import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import './Tabs.scss'
import classnames from 'classnames'

export const NavTabs = ({ tabs, className, direction = 'horizontal', children, ...props }) => {
  const [indicatorStyle, setIndicatorStyle] = useState({})
  const containerRef = useRef(null)
  const location = useLocation()

  useEffect(() => {
    const handleResize = () => {
      const selectedTab = containerRef.current.querySelector('.tab-btn.selected')
      if (selectedTab) {
        setIndicatorStyle(
          direction === 'vertical'
            ? {
                top: selectedTab.offsetTop || 0,
                height: selectedTab.offsetHeight || 20,
              }
            : {
                left: selectedTab.offsetLeft,
                width: selectedTab.offsetWidth,
              }
        )
      }
    }

    handleResize() // Initial positioning
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [children, direction, location])

  return (
    <div className="styled-tabs">
      <div className={classnames('styled-tabs-inside', className, direction)} ref={containerRef}>
        {children}
        <div className={`tab-indicator ${direction}`} style={indicatorStyle} />
      </div>
    </div>
  )
}

export const Tab = ({ to, className, children, ...props }) => {
  return (
    <NavLink
      to={to}
      className={({ isActive }) => classnames('tab-btn', { selected: isActive })}
      {...props}
    >
      {children}
    </NavLink>
  )
}
