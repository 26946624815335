import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  feedbacks: null,
}

const feedbacksSlice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    setFeedbacksSlice(state, action) {
      state.feedbacks = action.payload
    },
  },
})

export const { setFeedbacksSlice } = feedbacksSlice.actions

export default feedbacksSlice.reducer
