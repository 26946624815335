import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  submissions: null,
  scans: null,
  allTime: null,
  ratingOverTime: null,
  scansSubmissionsOverTime: null,
  heatmapStats: null,
}

const statsSlice = createSlice({
  name: 'stats',
  initialState,
  reducers: {
    setSubmissions(state, action) {
      state.submissions = action.payload
    },
    setScans(state, action) {
      state.scans = action.payload
    },
    setAllTime(state, action) {
      state.allTime = action.payload
    },
    setRatingOverTime(state, action) {
      state.ratingOverTime = action.payload
    },
    setScansSubmissionsOverTime(state, action) {
      state.scansSubmissionsOverTime = action.payload
    },
    setHeatmapStats(state, action) {
      state.heatmapStats = action.payload
    },
  },
})

export const { setSubmissions, setHeatmapStats, setScans, setAllTime, setRatingOverTime, setScansSubmissionsOverTime } =
  statsSlice.actions

export default statsSlice.reducer
