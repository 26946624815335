// src/redux/slices/pwaSlice.js
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  deferredPromptAvailable: false,
  isPWAInstalled:
    window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone,
}

const pwaSlice = createSlice({
  name: 'pwa',
  initialState,
  reducers: {
    setDeferredPromptAvailable(state, action) {
      state.deferredPromptAvailable = action.payload
    },
    setPWAInstalled(state, action) {
      state.isPWAInstalled = action.payload
    },
  },
})

export const { setDeferredPromptAvailable, setPWAInstalled } = pwaSlice.actions

export default pwaSlice.reducer
