import React from 'react'
import './App.scss'
import { BrowserRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import BoundaryKeyHandler from './components/error/ErrorBoundary'
import AppRouting from './AppRouting'
import { themeNames } from './utils/themeNames'
import { useSelector } from 'react-redux'

const App = () => {
  const theme = useSelector((state) => state.theme.theme)

  return (
    <div id="app" className={`theme-${themeNames[theme]} app`}>
      <BrowserRouter>
        <BoundaryKeyHandler>
          <AppRouting />
        </BoundaryKeyHandler>
      </BrowserRouter>
    </div>
  )
}

export default withTranslation()(App)
