import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  locations: null,
}

const locationsSlice = createSlice({
  name: 'locations',
  initialState,
  reducers: {
    setLocationsSlice(state, action) {
      state.locations = action.payload
    },
    addLocationSlice(state, action) {
      const loc = action.payload
      state.locations = state.locations.concat(loc)
    },
    removeLocationSlice(state, action) {
      const locArray = action.payload
      if (locArray.id) {
        state.locations = state.locations.filter((location) => locArray.id !== location.id)
      } else {
        const ids = locArray.ids
        state.locations = state.locations.filter((location) => !ids.includes(location.id))
      }
    },
    removeLocationsByCompanies(state, action) {
      const ids = action.payload
      state.locations = state.locations.filter((location) => !ids.includes(location.company))
    },
    changeLocationSlice(state, action) {
      const loc = action.payload
      state.locations = state.locations.map((location) => {
        if (location.id === loc.id) {
          return { ...location, ...loc }
        }
        return location
      })
    },
  },
})

export const {
  setLocationsSlice,
  addLocationSlice,
  removeLocationSlice,
  removeLocationsByCompanies,
  changeLocationSlice,
} = locationsSlice.actions

export default locationsSlice.reducer
