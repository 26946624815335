import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLoggedIn, resetUserState } from 'redux/ducks/user'
import { createAccount, login } from 'api/services/user'
import { useTranslation } from 'react-i18next'
import { setError } from 'redux/ducks/snackbar'

export const useUserAuth = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [hasRegistered, setHasRegistered] = useState(false)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const userLogin = async (data, rememberMe) => {
    setIsLoading(true)
    try {
      const res = await login(data)
      const token = res.data
      if (!rememberMe) {
        sessionStorage.setItem('jwt_access_token', token.access)
        sessionStorage.setItem('jwt_refresh_token', token.refresh)
      } else {
        localStorage.setItem('jwt_access_token', token.access)
        localStorage.setItem('jwt_refresh_token', token.refresh)
      }
      dispatch(setLoggedIn(true))
      navigate('/')
    } catch (err) {
      const error = err?.response?.data?.detail ? 'credentials-issue' : 'server-issue'
      dispatch(setError(t(`login-page-submit-error-${error}`)))
    }
    setIsLoading(false)
  }

  const userRegister = async (data) => {
    setIsLoading(true)
    try {
      await createAccount(data)
      setHasRegistered(true)
    } catch (err) {
      const error = err?.response ? Object.keys(err?.response?.data)?.[0] : 'server-issue'
      dispatch(setError(t(`register-page-submit-error-${error}`)))
    }
    setIsLoading(false)
  }

  const userLogout = () => {
    localStorage.removeItem('jwt_access_token')
    localStorage.removeItem('jwt_refresh_token')
    sessionStorage.removeItem('jwt_access_token')
    sessionStorage.removeItem('jwt_refresh_token')
    dispatch(resetUserState())
    navigate(0)
    navigate('/login')
  }

  return { userLogin, userLogout, userRegister, isLoading, hasRegistered }
}
