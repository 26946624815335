import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  codes: null,
}

const codesSlice = createSlice({
  name: 'codes',
  initialState,
  reducers: {
    setCodesSlice(state, action) {
      state.codes = action.payload
    },

    addCodeSlice(state, action) {
      const code = action.payload
      if (state.codes) {
        state.codes = state.codes.concat(code)
      }
    },

    removeCodeSlice(state, action) {
      const codeArray = action.payload
      if (state.codes) {
        if (codeArray.id) {
          state.codes = state.codes.filter((code) => code.id !== codeArray.id)
        } else {
          const ids = codeArray.ids
          state.codes = state.codes.filter((code) => !ids.includes(code.id))
        }
      }
    },
    removeCodesByCompanies(state, action) {
      const ids = action.payload
      state.codes = state.codes.filter((code) => !ids.includes(code.company))
    },
    removeCodesByLocations(state, action) {
      const ids = action.payload
      state.codes = state.codes.filter((code) => !ids.includes(code.site))
    },
    changeCodeSlice(state, action) {
      const code = action.payload
      if (state.codes) {
        state.codes = state.codes.map((fm) => {
          if (fm.id === code.id) {
            return { ...fm, ...code }
          }
          return fm
        })
      }
    },
  },
})

export const {
  setCodesSlice,
  addCodeSlice,
  removeCodeSlice,
  removeCodesByCompanies,
  removeCodesByLocations,
  changeCodeSlice,
} = codesSlice.actions

export default codesSlice.reducer
