import axios from 'axios'

const makeAuthedRequest = async (url, method, data = {}, token) => {
  try {
    const response = await axios({
      url,
      method,
      headers: {
        Authorization: token,
        'Content-Type': 'application/json',
      },
      data,
    })
    return response.data
  } catch (error) {
    throw error
  }
}

export default makeAuthedRequest
