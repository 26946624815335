import { lazy } from 'react'

export const Sidebar = lazy(() => import('components/navbar/app-sidebar/Sidebar'))
export const HomeAuthorized = lazy(() => import('pages/home/HomeAuthorized'))
export const Faq = lazy(() => import('pages/faq/Faq'))
export const StyledSnackbar = lazy(() => import('components/snackbar/StyledSnackbar'))
export const Stats = lazy(() => import('pages/stats/Stats'))
export const QrFeedback = lazy(() => import('pages/qr-viewer/QrFeedback'))
export const Companies = lazy(() => import('pages/companies/Companies'))
export const Locations = lazy(() => import('pages/locations/Locations'))
export const QRCodes = lazy(() => import('pages/qr-creator/creator-codes/qr-codes/QRCodes'))
export const Feedback = lazy(() => import('pages/feedback/Feedback'))
export const Account = lazy(() => import('pages/account/Account'))
export const CreatorSidebar = lazy(() => import('components/navbar/creator-navbar/CreatorSidebar'))
export const AccountActivation = lazy(() => import('pages/login/activation-setup/AccountActivation'))
export const RegisterActivationContainer = lazy(() => import('pages/register/activation/RegisterActivationContainer'))
export const ResetPassword = lazy(() => import('pages/reset-pass/ResetPassword'))
export const ResetPassSetNewContainer = lazy(() => import('pages/reset-pass/set-new-pass/ResetPassSetNewContainer'))
export const ResendActivationContainer = lazy(() =>
  import('pages/register/resend-activation/ResendActivationContainer')
)
export const Login = lazy(() => import('pages/login/Login'))
export const TempRegister = lazy(() => import('pages/register/TempRegister'))
export const Register = lazy(() => import('pages/register/Register'))
export const QrForms = lazy(() => import('pages/qr-creator/creator-codes/qr-forms/QrForms'))

export const FormEditor = lazy(() => import('pages/qr-creator/creator-codes/qr-forms/create-form/FormEditor'))
export const QrCodeCreateEdit = lazy(() =>
  import('pages/qr-creator/creator-codes/qr-codes/create-edit-code/QrCodeCreateEdit')
)
export const QrFrames = lazy(() => import('pages/qr-creator/creator-codes/qr-frames/QrFrames'))
export const FrameEditor = lazy(() => import('pages/qr-creator/creator-codes/qr-frames/create-frame/FrameEditor'))

export const QrGeneralInfo = lazy(() => import('pages/qr-creator/creator-codes/qr-general-info/QrGeneralInfo'))

export const CreatorContainer = lazy(() => import('pages/qr-creator/creator-codes/CreatorContainer'))

export const PWA = lazy(() => import('pages/pwa/PWA'))
