import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setDeferredPromptAvailable, setPWAInstalled } from 'redux/ducks/pwa'
import PrimaryButton from 'components/buttons/PrimaryButton'

const PWAprompt = () => {
  const dispatch = useDispatch()
  const deferredPrompt = useRef(null)
  const promptAvailable = useSelector((state) => state.pwa.deferredPromptAvailable)
  const isPWAInstalled = useSelector((state) => state.pwa.isPWAInstalled)

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault()
      deferredPrompt.current = e
      dispatch(setDeferredPromptAvailable(true))
    }

    const handleAppInstalled = () => {
      dispatch(setPWAInstalled(true))
    }

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
    window.addEventListener('appinstalled', handleAppInstalled)

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt)
      window.removeEventListener('appinstalled', handleAppInstalled)
    }
  }, [])

  const handleInstallClick = () => {
    if (deferredPrompt.current) {
      deferredPrompt.current.prompt()
      deferredPrompt.current.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt')
        } else {
          console.log('User dismissed the A2HS prompt')
        }
        deferredPrompt.current = null
        dispatch(setDeferredPromptAvailable(false))
      })
    }
  }

  // Hide the button if PWA is already installed
  if (isPWAInstalled) {
    return null
  }

  return (
    <PrimaryButton
      id="install-button"
      style={{
        display: promptAvailable ? 'block' : 'none',
        width: '340px',
      }}
      onClick={handleInstallClick}
    >
      Install app
    </PrimaryButton>
  )
}

export default PWAprompt
