import React from 'react'
import Typography from '../../typography/Typography'
import { version, hostEnv } from '../../../utils/environment'
import './SidebarFooter.scss'
import TextLink from '../../buttons/TextLink'
import classnames from 'classnames'

const SidebarFooter = ({ compact, ...props }) => {
  const currentYear = new Date().getFullYear()
  return (
    <div className={classnames('sidebar-footer', { compact: compact })}>
      <Typography variant="subheading2">
        ©{' '}
        <TextLink to="https://maoni.us" target="_blank" rel="noreferrer">
          maoni.us
        </TextLink>
        <div className="versioning">
          {currentYear} {`v${version}${hostEnv}`}
        </div>
      </Typography>
    </div>
  )
}

export default SidebarFooter
