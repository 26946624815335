import React from 'react'
import './PrimaryButton.scss'
import classnames from 'classnames'

const PrimaryButton = ({ className, fieldStyle, overallStyle, children, ...props }) => {
  const styling = {
    color: fieldStyle?.labelColor || overallStyle?.backgroundColor,
    backgroundColor: fieldStyle?.btnColor || overallStyle?.accentColor,
    fontSize: fieldStyle?.fontSize,
    width: overallStyle ? '320px' : '',
    marginTop: overallStyle ? '20px' : '',
  }

  return (
    <button className={classnames('primary-btn btn', className)} style={styling} {...props}>
      {children}
    </button>
  )
}

export default PrimaryButton
